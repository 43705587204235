<template>
  <div class="login-page">
    <div class="login-wrapper">
      <div class="login-max-width">
        <h1>Login</h1>
        <form @submit="login">
          <div class="field-wrapper">
            <label for="email" class="">Email</label>
            <input
              id="email"
              name="email"
              type="email"
              placeholder="Insert"
              autocomplete
              v-model="email"
            />
          </div>
          <div class="field-wrapper">
            <label for="password">Password</label>
            <input
              id="password"
              name="password"
              type="password"
              placeholder="Insert"
              autocomplete
              v-model="password"
            />
          </div>
          <p v-show="error" class="show error text-sm text-red-500">{{ errorMsg }}</p>
          <div class="bottom-wrapper">
            <p>
              <router-link to="/forgot-password">Forgot Password?</router-link>
            </p>
            <button type="submit" style="display: none">Submit</button>
            <div @click.prevent="login">
              <MyButton name="Submit" target="" url=""></MyButton>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { login_info } from "@/scripts/global.js";
import { strapi_api } from "@/scripts/strapi_api";

export default {
  name: "Login",
  data() {
    return {
      strapi_api,
      login_info,
      email: "",
      password: "",
      error: false,
      errorMsg: `An error occurred, please try again`,
    };
  },
  methods: {
    login(e) {
      e.preventDefault();

      let args = {
        identifier: this.email,
        password: this.password,
      };
      strapi_api.post_strapi(this.axios, "auth/local", args).then(
        (response) => {
          //Promisse returned successfully
          if (response.status != 200) {
            this.error = true;
            this.errorMsg = response.data.error.message;
            return;
          }
          const { jwt, user } = response.data;
          sessionStorage.setItem("jwt", jwt);
          sessionStorage.setItem("userData", JSON.stringify(user));
          this.login_info.set_is_logged_in(true);
          this.login_info.set_username(user.Name);

          if (
            (sessionStorage.getItem("redirect_after_login_data") &&
              sessionStorage.getItem("redirect_after_login_data") != "/login") ||
            (sessionStorage.getItem("redirect_after_login_data") == "/" && isAdmin)
          ) {
            window.location.href = sessionStorage.getItem("redirect_after_login_data");
          } else {
            window.location.href = "/";
            //window.location.href = "/projects";
            //this.$router.push("/projects");
          }
        },
        () => {
          //something went wrong with promissew
        }
      );
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
@import "@/style/views/login";
</style>
