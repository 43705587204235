export default {
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["welcome"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logout"])},
  "my_projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Projects"])},
  "my_organizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Organizations"])},
  "unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized"])},
  "no_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don´t have permissions to see this content."])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["version"])},
  "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["go"])}
}