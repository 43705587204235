<template>
  <a :href="url" :traget="target" v-if="url!=''" class="link">
    {{ name }}
    <div class="icon-wrapper">
      <svg class="back">
        <use href="@/assets/icons.svg#ico-big-arrow" />
      </svg>
      <svg class="front">
        <use href="@/assets/icons.svg#ico-big-arrow" />
      </svg>
    </div>
  </a>
  <div v-if="url==''" class="link">
    {{ name }}
    <div class="icon-wrapper">
      <svg class="back">
        <use href="@/assets/icons.svg#ico-big-arrow" />
      </svg>
      <svg class="front">
        <use href="@/assets/icons.svg#ico-big-arrow" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "LinkArrow",
  props: {
    url: String,
    target: String,
    name: String
  }
};
</script>

<style lang="scss" scoped>
  @import '@/style/components/link-arrow';
</style>