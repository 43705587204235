// strapi_api.js
import { reactive } from 'vue'

export const strapi_api = reactive({
    strapi_bo_url: process.env.VUE_APP_STRAPI_IP,
    strapi_bo_api_key: process.env.VUE_APP_STRAPI_BASE_API_TOKEN,
    async get_strapi(axios, endpoint, query) {
        try {
            let locale = sessionStorage.getItem('lang');
            if (locale == null) {
                locale = 'en';
                sessionStorage.setItem('lang', 'en');
            }

            if(query){
                query = '&' + query;
            }else{
                query = '';
            }
            let token = sessionStorage.getItem("jwt") ? sessionStorage.getItem("jwt") : this.strapi_bo_api_key;
            //use populate=* to return all fields  organizations
            const res = await axios.get(this.strapi_bo_url + '/api/' + endpoint + '?locale=' + locale + query, {
                headers: {
                    Authorization:
                        'Bearer ' + token,
                },
            });
            return res;
        }
        catch (error) {
            return false;
        }
    },
    async post_strapi(axios, endpoint, args) {
        try {
            const res = await axios.post(this.strapi_bo_url + '/api/' + endpoint, args);
            return res;
        } catch (error) {
            return error.response;
        }
    },
    get_strapi_bo_url() {
        return this.strapi_bo_url;
    }
})