<template>
  <div id="nav" class="container wide">
    <div class="home">
      <img v-if="!login_info.get_is_logged_in()" src="@/assets/tinsight_logo.svg" />
      <router-link
        v-if="this.checkBackToOrganizations($route)"
        @click="this.login_info.set_orgLogo('')"
        :to="{
          name: 'Organizations',
        }"
        :key="'/organizations'"
      >
        <img src="@/assets/tinsight_logo.svg" />
      </router-link>

      <router-link
        v-if="login_info.get_is_logged_in() && !this.checkBackToOrganizations($route)"
        :to="{
          name: 'Projects',
        }"
        :key="'/projects'"
      >
        <img src="@/assets/tinsight_logo.svg" />
      </router-link>
    </div>

    <div data-aos="fade-left" v-if="login_info.is_logged_in" class="logged-in-info">
      <div class="left">
        <img
          v-if="login_info.get_is_logged_in() && this.login_info.get_orgLogo() == ''"
          src="@/assets/tinsight_user_logo.svg"
        />
        <img
          v-if="login_info.get_is_logged_in() && this.login_info.get_orgLogo() != ''"
          :src="this.login_info.get_orgLogo()"
        />
        {{ this.login_info.get_username() }}
      </div>

      <div class="logout" @click="this.logout()">
        {{ $t("logout") }}
      </div>
    </div>
  </div>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
  <footer class="container wide">
    <div class="left">
      <a class="pink" href="https://www.tinsight.pt" target="_blank"> t_insight </a>
      <a href="https://www.instagram.com/t_insight/" target="_blank"> Instagram </a>
      <a href="https://www.facebook.com/tinsight" target="_blank"> Facebook </a>
      <a href="https://www.linkedin.com/company/t_insight/mycompany/" target="_blank">
        LinkedIn
      </a>
    </div>
    <div class="right">
      Need help?
      <a href="mailto:geral@tinsight.pt"> geral@tinsight.pt </a>
    </div>
  </footer>
  <div class="fullscreen-modal">
    <div class="text-wrapper">
      <h2>{{ $t("unauthorized") }}</h2>
      <p>{{ $t("no_permission") }}</p>
      <div @click="this.redirect_to_projects">
        <a href="/projects" class="link-wrapper">
          <LinkArrow name="BACK" target="" url=""></LinkArrow>
        </a>
      </div>
    </div>
  </div>
  <!-- <div class="gradient-bg">
    <div class="logo-wrapper">
      <img :src="orgLogo" />
    </div>
  </div> -->
</template>

<script>
import { login_info } from "@/scripts/global.js";
import { strapi_api } from "@/scripts/strapi_api.js";

export default {
  name: "App",
  data() {
    return {
      strapi_api,
      login_info,
      area: "/",
      orgLogo: "",
      bgColor: "linear-gradient(95deg, #F00094 0%, #8A20F7 100%)",
    };
  },
  methods: {
    check_alias() {
      //Debug
      if (window.location.hostname.split(".")[0] == "admin") {
        return "admin";
      }
      if (window.location.hostname.split(".")[0].split("_")[0] == "stage") {
        return window.location.hostname.split(".")[0].split("_")[1];
      } else {
        return window.location.hostname.split(".")[0];
      }
    },
    getImageUrl(imageAttr) {
      return this.strapi_api.get_strapi_bo_url() + imageAttr.data.attributes.url;
    },
    checkBackToOrganizations($route) {
      //check if organization is t_insight
      if (this.login_info.get_alias() == "admin" && this.login_info.get_is_admin()) {
        return $route.path.indexOf("/project") == 0 || $route.path == "/";
      } else {
        return false;
      }
    },
    checkAuth() {
      //check if url has parameters
      if (window.location.href.indexOf("?") != -1) {
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("jwt")) {
          sessionStorage.setItem("jwt", urlParams.get("jwt"));
        }
        if (urlParams.get("userData")) {
          sessionStorage.setItem("userData", urlParams.get("userData"));
        }
        if (urlParams.get("redirect_after_login_data")) {
          sessionStorage.setItem(
            "redirect_after_login_data",
            urlParams.get("redirect_after_login_data")
          );
        }
        if (urlParams.get("lang")) {
          sessionStorage.setItem("lang", urlParams.get("lang"));
        }

        setTimeout(() => {
          let newUrl =
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname;
          window.history.replaceState(null, null, newUrl);
        }, 500);
      }

      let isAccessingAdmin = this.login_info.get_accessing_admin();
      let alias = this.check_alias();

      this.login_info.set_alias(alias);

      if (isAccessingAdmin) {
        this.login_info.set_alias("admin");
      } else {
        this.login_info.set_alias(alias);
      }

      //jwt cookie used for authentication
      let jwt = sessionStorage.getItem("jwt");

      if (isAccessingAdmin) {
        sessionStorage.setItem("redirect_after_login_data", "/");
      } else {
        sessionStorage.setItem("redirect_after_login_data", "/projects");
      }

      if (jwt == null) {
        document.querySelector("#app").style.opacity = "1";
        if (window.location.href.indexOf("resetpassword") == -1) {
          let setPath = "";
          if (window.location.pathname == "/") {
            setPath = "/";
          } else {
            setPath = window.location.pathname;
          }
          sessionStorage.setItem("redirect_after_login_data", setPath);
          this.$router.push("/login");
        }
      } else {
        this.strapi_api.get_strapi(this.axios, "users/me/").then(
          (response) => {
            if (!response) {
              sessionStorage.removeItem("jwt");
              sessionStorage.removeItem("userData");

              this.login_info.set_is_logged_in(false);
              this.login_info.set_username("");
              sessionStorage.setItem(
                "redirect_after_login_data",
                window.location.pathname
              );
              document.querySelector("#app").style.opacity = "1";
              this.$router.push("/login");
            } else {
              let user_id = response.data.id;

              if (response.data.Admin) {
                this.login_info.set_absolute_admin(true);
              }

              let query = "populate=Name,Alias,Logo,Color,Projects,Projects.Editors";

              //TODO find a way to avoid passing axios here
              this.strapi_api.get_strapi(this.axios, "organizations", query).then(
                (response) => {
                  let differentOrgs = [];
                  response.data.data.forEach((organization) => {
                    organization.attributes.Projects.data.forEach((project) => {
                      if (
                        project.attributes.Editors.data.some(
                          (editor) => editor.id == user_id
                        ) ||
                        this.login_info.get_absolute_admin()
                      ) {
                        if (!differentOrgs.includes(organization)) {
                          differentOrgs.push(organization);
                        }
                      }
                    });
                    if (organization.attributes.Alias == alias) {
                      this.login_info.set_orgLogo(
                        this.strapi_api.get_strapi_bo_url() +
                          organization.attributes.Logo.data.attributes.url
                      );
                    }
                  });
                  this.login_info.set_is_logged_in(true);

                  let userData = JSON.parse(sessionStorage.getItem("userData"));
                  this.login_info.set_username(userData.Name);
                  this.login_info.set_organizations(differentOrgs.length);
                  if (differentOrgs.length <= 1 && isAccessingAdmin) {
                    document.querySelector(".fullscreen-modal").classList.add("display");
                    document.querySelector(".fullscreen-modal").classList.add("show");
                  } else if (isAccessingAdmin && differentOrgs.length > 1) {
                    this.login_info.set_is_admin(true);
                  }

                  document.querySelector("#app").style.opacity = "1";

                  if (
                    (this.login_info.get_organizations() <= 1 &&
                      this.login_info.get_accessing_admin() &&
                      !this.login_info.get_is_admin()) ||
                    (this.login_info.get_organizations() > 1 &&
                      !this.login_info.get_accessing_admin() &&
                      this.$router.currentRoute._value.fullPath.indexOf("project/") != 1)
                  ) {
                    this.$router.push("/projects");
                  }

                  //get current path

                  /* if (this.login_info.get_organizations() <= 1) {
                    this.$router.push("/projects");
                  } */
                },
                () => {
                  //something went wrong with promisse
                }
              );
            }
          },
          () => {
            //something went wrong with promisse
          }
        );
      }
    },
    logout() {
      if (confirm("Do you really want to leave?")) {
        sessionStorage.removeItem("jwt");
        sessionStorage.removeItem("userData");
        this.login_info.set_is_logged_in(false);
        this.login_info.set_username("");
        this.$router.push("/login");
      }
    },
    set_locale(locale) {
      this.$i18n.locale = locale;
      sessionStorage.setItem("lang", locale);
      this.$router.push("/");
      window.location.reload();
    },
    redirect_to_projects() {
      document.querySelector(".fullscreen-modal").classList.remove("show");
      setTimeout(function () {
        document.querySelector(".fullscreen-modal").classList.remove("display");
      }, 1000);
    },
  },
  mounted: function () {
    if (sessionStorage.getItem("lang") == null) {
      sessionStorage.setItem("lang", "en");
    }

    this.$i18n.locale = sessionStorage.getItem("lang");
    this.checkAuth();
  },
};
</script>

<style lang="scss">
@import "@/style/global";
</style>
