<template>
  <a :href="url" :traget="target" v-if="url != ''" class="guidelines-btn">
    <p>
      {{ name }}
    </p>
  </a>
  <div v-if="url == ''" class="guidelines-btn">
    <p>
      {{ name }}
    </p>
  </div>
</template>

<script>
export default {
  name: "MyButton",
  props: {
    url: String,
    target: String,
    name: String,
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/components/button";
</style>
