<template>
  <div class="projects-wrapper show">
    <div v-if="this.org" class="container">
      <div class="org-details">
        <img :src="this.getImageUrl(org.Logo)" />
        {{ org.Name }}
      </div>
      <div class="projects">
        <a
          class="project"
          v-for="project in org.Allowed_Projects"
          :key="project.id"
          :href="`/project/${project.id}`"
        >
          <svg>
            <use :href="this.base_url + `#ico-${project.attributes.Type}-project`" />
          </svg>
          {{ project.attributes.Name }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { login_info } from "@/scripts/global";
import { strapi_api } from "@/scripts/strapi_api.js";
export default {
  name: "projects",

  data() {
    return {
      base_url: require("@/assets/icons.svg"),
      login_info,
      org: false,
      projects: [],
      strapi_api,
    };
  },
  methods: {
    redirect_to_project(id) {
      this.$router.push("/project/" + id);
      window.location.href = "/project/" + id;
    },
    getImageUrl(imageAttr) {
      return this.strapi_api.get_strapi_bo_url() + imageAttr.data.attributes.url;
    },
    /* getProjects(org_projects) {
      function compare(a, b) {
        if (a.attributes.Rank < b.attributes.Rank) {
          return -1;
        }
        if (a.attributes.Rank > b.attributes.Rank) {
          return 1;
        }
        return 0;
      }

      let userData = JSON.parse(sessionStorage.getItem("userData"));
      let query =
        "populate=Name,Email,Logo,Intro,Editors&filters[Editors][id]=" + userData.id;

      //TODO find a way to avoid passing axios here
      this.strapi_api.get_strapi(this.axios, "projects", query).then(
        (response) => {
          this.projects = [];
          //Promisse returned successfully
          response.data.data.forEach((project) => {
            if (org_projects.includes(project.id)) {
              this.projects.push(project);
            }
          });
          this.projects.sort(compare);
        },
        () => {
          //something went wrong with promisse
        }
      );
    }, */
    getOrganization() {
      //window.location.hostname.split('.')[0];
      let alias = this.login_info.get_alias();

      let query =
        "populate=Name,Logo,Projects,Projects.Editors,Projects.Logo,Projects.Name,Projects.Rank,Projects.Type&filters[Alias][$eq]=" +
        alias;

      if (alias == "admin") {
        query =
          "populate=Name,Logo,Projects,Projects.Editors,Projects.Logo,Projects.Name,Projects.Rank,Projects.Type";
      }

      let userData = JSON.parse(sessionStorage.getItem("userData"));
      let user_id = userData.id;

      //TODO find a way to avoid passing axios here
      this.strapi_api.get_strapi(this.axios, "organizations", query).then(
        (response) => {
          //Promisse returned successfully
          this.org = response.data.data[0].attributes;
          this.org.Projects.data.forEach((project) => {
            if (
              project.attributes.Editors.data.some((editor) => editor.id == user_id) ||
              this.login_info.get_absolute_admin()
            ) {
              if (this.org.Allowed_Projects) {
                this.org.Allowed_Projects.push(project);
              } else {
                this.org.Allowed_Projects = [project];
              }
            }
          });
          this.org.Allowed_Projects.sort(function (a, b) {
            if (a.attributes.Rank < b.attributes.Rank) {
              return -1;
            }
            if (a.attributes.Rank > b.attributes.Rank) {
              return 1;
            }
            return 0;
          });
        },
        () => {
          //something went wrong with promisse
        }
      );
    },
  },
  mounted: function () {
    this.getOrganization();
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/views/projects_landing";
</style>
