<template>
    <div class="sidebar-section" v-if="content.length > 0">
        <div class="label" @click="this.triggerOpenSection($event);" >
            {{name}}
            <svg class="ico-arrow">
                <use href="@/assets/icons.svg#ico-arrow" />
            </svg>
        </div>
        <div class="dropdown-list">
            <div class="dropdown-item" v-for="section in content">
                <div class="inner-label" @click="this.choose_correct_function(section.sub_items, $event, section.social)" :data-viewid="section.sub_items.length > 0?'false' : this.getInsideIds(section.social)">
                    {{section.name}}
                </div>
                <div v-if="section.sub_items">
                    <div class="inner-list" :style="'max-height : ' + this.innerMaxHeight[section.name] + 'px'">
                        <div class="final-label" v-for="item in section.sub_items" @click="this.triggerSetView($event,item.social)" :data-viewid="item.social[0] ? getInsideIds(item.social) : 'false'">
                            {{item.label}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
  export default {
    name: "SidebarSection",
    props: {
      name: String,
      content: Array,
      id: Number
    },
    data(){
        return {
            maxHeight : 0,
            innerMaxHeight : {}
        };
    },
    methods: {
        getInsideIds(social){
            let output = [];
            social.forEach(element => {
                output.push(element.id);
            });
            return output;
        },

        choose_correct_function(childs, e, social){
            if(childs){
                this.$emit('openInnerSection',e);
            }else{
                this.$emit('setView',e,social, social[0].id);
            }
        },
        triggerOpenSection(e){
            this.$emit('openSection', e);
        },
        triggerSetView(e, social){
            this.$emit('setView', e, social, social[0].id);
        }
    }
  };
  </script>
<style lang="scss" scoped>
    @import '@/style/components/sidebar';
 </style>

  

