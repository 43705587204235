<template>
  <div class="organizations-wrapper container show" @click="this.clicked = 0">
    <div class="organizations-list">
      <a
        data-aos="fade-up"
        :data-aos-delay="counter * 200"
        class="org-wrapper"
        :class="{ active: org.id == this.clicked, 'aos-init aos-animate': true }"
        href="#"
        @click.prevent="this.setCurrtentOrganization($event, org.id)"
        v-for="(org, counter) in organizations"
      >
        <img :src="this.getImageUrl(org.attributes.Logo)" />
        <p class="org-name">
          {{ org.attributes.Name }}
        </p>
      </a>
    </div>
    <div class="organization-detail" :class="{ active: this.current_org }">
      <div v-if="this.current_org" class="container">
        <svg @click="this.current_org = false" class="close">
          <use href="@/assets/icons.svg#ico-cross" />
        </svg>
        <div class="org-details">
          <img :src="this.getImageUrl(current_org.Logo)" />
          {{ current_org.Name }}
        </div>
        <div class="projects">
          <a
            class="project"
            v-for="project in current_org.Allowed_Projects"
            :key="project.id"
            :href="`/project/${project.id}`"
          >
            <svg>
              <use :href="this.base_url + `#ico-${project.attributes.Type}-project`" />
            </svg>
            {{ project.attributes.Name }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { login_info } from "@/scripts/global.js";
import { strapi_api } from "@/scripts/strapi_api.js";
export default {
  name: "organizations",
  data() {
    return {
      login_info,
      base_url: require("@/assets/icons.svg"),
      organizations: [],
      strapi_api,
      clicked: 0,
      current_org: false,
    };
  },
  methods: {
    setCurrtentOrganization(event, id) {
      event.stopPropagation();
      if (!this.current_org) {
        //scroll to top
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        this.organizations.forEach((element) => {
          if (element.id == id) {
            this.current_org = element.attributes;
          }
        });
      } else {
        this.current_org = false;
      }
    },
    getImageUrl(imageAttr) {
      return this.strapi_api.get_strapi_bo_url() + imageAttr.data.attributes.url;
    },
    appendSessionStorage() {
      let lang = sessionStorage.getItem("lang");
      let redirect_after_login_data = sessionStorage.getItem("redirect_after_login_data");
      let jwt = sessionStorage.getItem("jwt");
      let userData = sessionStorage.getItem("userData");
      return `?lang=${encodeURIComponent(
        lang
      )}&redirect_after_login_data=${encodeURIComponent(
        redirect_after_login_data
      )}&jwt=${encodeURIComponent(jwt)}&userData=${encodeURIComponent(userData)}`;
    },
    getOrganizations() {
      let userData = JSON.parse(sessionStorage.getItem("userData"));
      let user_id = userData.id;
      //window.location.hostname.split('.')[0];
      let query =
        "populate=Name,Alias,Logo,Rank,Projects,Projects.Editors,Projects.Logo,Projects.Name,Projects.Rank,Projects.Type";

      //TODO find a way to avoid passing axios here
      this.strapi_api.get_strapi(this.axios, "organizations", query).then(
        (response) => {
          let differentOrgs = 0;

          //Promisse returned successfully
          response.data.data.forEach((organization) => {
            organization.attributes.Projects.data.forEach((project) => {
              if (
                project.attributes.Editors.data.some((editor) => editor.id == user_id) ||
                this.login_info.get_absolute_admin()
              ) {
                if (organization.attributes.Allowed_Projects) {
                  organization.attributes.Allowed_Projects.push(project);
                } else {
                  organization.attributes.Allowed_Projects = [project];
                  differentOrgs++;
                }

                if (!this.organizations.includes(organization)) {
                  this.organizations.push(organization);
                }
              }
            });

            this.organizations.sort(function (a, b) {
              if (a.attributes.Rank < b.attributes.Rank) {
                return -1;
              }
              if (a.attributes.Rank > b.attributes.Rank) {
                return 1;
              }
              return 0;
            });

            //foreach organization order allowed projects by name
            this.organizations.forEach((organization) => {
              organization.attributes.Allowed_Projects.sort(function (a, b) {
                if (a.attributes.Rank < b.attributes.Rank) {
                  return -1;
                }
                if (a.attributes.Rank > b.attributes.Rank) {
                  return 1;
                }
                return 0;
              });
            });
          });
        },
        () => {
          //something went wrong with promisse
        }
      );
    },
  },
  mounted: function () {
    this.getOrganizations();

    document.querySelector(".organizations-list").addEventListener("click", (event) => {
      this.current_org = false;
      this.login_info.set_orgLogo("");
    });
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/views/organizations_landing";
</style>
