// global.js
import { reactive } from 'vue'

export const login_info = reactive({
    is_logged_in: false,
    username: '',
    logo: '/assets/t_insight_default.png',
    absolute_admin: false,
    is_admin: false,
    is_accessing_admin: false,
    alias: '',
    orgLogo: '',
    organizations: 0,

    get_absolute_admin() {
        return this.absolute_admin;
    },
    get_accessing_admin() {
        if (window.location.hostname.split(".")[0] == "admin") {
            return true;
          }else{
            return false;
          }
    },

    get_organizations() {
        return this.organizations;
    },

    get_alias() {
        return this.alias;
    },

    get_orgLogo() {
        return this.orgLogo;
    },

    get_is_admin() {
        return this.is_admin;
    },
    get_logo() {
        return this.logo;
    },
    get_username() {
        return this.username;
    },
    get_is_logged_in() {
        return this.is_logged_in;
    },
    set_organizations(val) {
        this.organizations = val;
    },
    set_alias(val) {
        this.alias = val;
    },
    set_orgLogo(val) {
        this.orgLogo = val;
    },
    set_is_admin(val) {
        this.is_admin = val;
    },
    set_absolute_admin(val) {
        this.absolute_admin = val;
    },
    set_logo(val) {
        this.logo = val;
    },
    set_username(val) {
        this.username = val;
    },
    set_is_logged_in(val) {
        this.is_logged_in = val;
    },
    scrollTo(elem, pos = null, offset = 0) {
        if (pos == null) {
            if (typeof elem === 'object' && elem !== null && 'getBoundingClientRect' in elem) {
                pos = elem.getBoundingClientRect().top + window.scrollY;
            } else {
                pos = elem.top + window.scrollY;
            }
        }
        pos = +pos;
        window.scroll({
            top: pos + offset,
            left: 0,
            behavior: 'smooth'
        });
    },
    resizeIframe(obj) {
        obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
    }
})