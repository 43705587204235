// router/index.js
import { createWebHistory, createRouter } from "vue-router";

import AOS from 'aos';

import Organizations from "@/views/Organizations.vue";
import Login from "@/views/Login.vue";
import Projects from "@/views/Projects.vue";
import Project_Detail from "@/views/ProjectDetail.vue";
import ForgotPassword from '@/views/ForgotPassword.vue';
import ResetPassword from '@/views/ResetPassword.vue';
const routes = [
    {
        path: "/",
        name: "Organizations",
        component: Organizations,
        meta: {
            title: 't_insight | Digital Guidelines',
            metaTags: [
                {
                    name: 'description',
                    content: 'List of your Organizations'
                },
                {
                    property: 'og:description',
                    content: 'List of your Organizations'
                }
            ]
        }
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            title: 'Login',
            metaTags: [
                {
                    name: 'description',
                    content: 'Login to see your organization guidelines'
                },
                {
                    property: 'og:description',
                    content: 'Login to see your organization guidelines'
                }
            ]
        }
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: {
            title: 'Forgot Password',
            metaTags: [
                {
                    name: 'description',
                    content: 'Recover your password'
                },
                {
                    property: 'og:description',
                    content: 'Recover your password'
                }
            ]
        }
    },
    {
        path: '/resetpassword',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
            title: 'Reset Password',
            metaTags: [
                {
                    name: 'description',
                    content: 'Set a new password'
                },
                {
                    property: 'og:description',
                    content: 'Set a new password'
                }
            ]
        }
    },
    {
        path: "/projects",
        name: "Projects",
        component: Projects,
        meta: {
            title: 'Projects',
            metaTags: [
                {
                    name: 'description',
                    content: 'List of your projects'
                },
                {
                    property: 'og:description',
                    content: 'List of your projects'
                }
            ]
        }
    },
    {
        path: "/project/:slug?",
        name: "ProjectDetail",
        component: Project_Detail,
        meta: {
            title: 't_insight | Digital Guidelines',
            metaTags: [
                {
                    name: 'description',
                    content: 'Follow this digital guidelines to improve your bussiness'
                },
                {
                    property: 'og:description',
                    content: 'Follow this digital guidelines to improve your bussiness'
                }
            ],
            watchParam: 'slug'
        }
    },
    {
        path: "/project/:slug/:view?",
        name: "ProjectDetail",
        component: Project_Detail,
        meta: {
            title: 't_insight | Digital Guidelines',
            metaTags: [
                {
                    name: 'description',
                    content: 'Follow this digital guidelines to improve your bussiness'
                },
                {
                    property: 'og:description',
                    content: 'Follow this digital guidelines to improve your bussiness'
                }
            ]
        }
    }
]



const router = createRouter({
    history: createWebHistory(),
    routes,
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    AOS.init(); // Initialize AOS
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else if (previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));

    next();
});

export default router;