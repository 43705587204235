<template>
  <div class="login-page">
    <div class="login-wrapper">
      <div class="login-max-width">
        <h1 v-if="!done">Password Reset</h1>
        <p v-if="!done">Insert your new password :)</p>

        <h1 v-if="done">Success!</h1>
        <p v-if="done">Your password has been successfully altered.</p>
        <form @submit="resetPassword" v-if="!done">
          <div class="field-wrapper">
            <label>&nbsp;</label>
            <input type="password" v-model="password" />
          </div>
          <div class="field-wrapper">
            <label>Password Confirmation</label>
            <input type="password" v-model="confirmPassword" />
          </div>
          <p v-show="error" class="show error text-sm text-red-500">{{ errorMsg }}</p>
          <div class="bottom-wrapper">
            <p></p>
            <div
              @click="resetPassword"
              :disabled="password.length < 3 || password !== confirmPassword"
            >
              <MyButton name="Submit" target="" url=""></MyButton>
            </div>
          </div>
        </form>
        <div v-if="done" class="gotit-wrapper">
          <a href="/login" class="link-wrapper"> Go to login screen </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { strapi_api } from "@/scripts/strapi_api";
export default {
  name: "ResetPassword",
  data() {
    return {
      password: "",
      confirmPassword: "",
      done: false,
      error: false,
    };
  },
  methods: {
    resetPassword(e) {
      e.preventDefault();
      strapi_api
        .post_strapi(this.axios, "auth/reset-password", {
          code: this.$route.query.code,
          password: this.password,
          passwordConfirmation: this.confirmPassword,
        })
        .then((response) => {
          if (response.status != 200) {
            this.error = true;
            this.errorMsg = response.data.error.message;
            return;
          } else {
            this.done = true;
          }
        })
        .catch((e) => {
          e;
          this.error = true;
          this.errorMsg = "Link Expired";
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/views/login";
</style>
