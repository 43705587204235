export default {
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bem-vindo"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["casa"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sair"])},
  "my_projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os Meus Projectos"])},
  "my_organizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As minhas Organizações"])},
  "unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não Autorizado"])},
  "no_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não tens permissiões para ver este conteúdo."])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["versão"])},
  "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ir"])}
}