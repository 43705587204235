<template>
  <div class="login-page">
    <div class="login-wrapper">
      <div class="login-max-width">
        <h1>Forgot your Password?</h1>

        <p v-if="!done">Please enter your email</p>

        <p v-if="done">Please check your email and follow the instructions</p>

        <form @submit="forgotPassword" v-if="!done">
          <div class="field-wrapper">
            <label class="">Email</label>
            <input type="email" v-model="email" />
          </div>
          <p v-show="error" class="show error text-sm text-red-500">{{ errorMsg }}</p>
          <div class="bottom-wrapper">
            <p></p>
            <div @click="forgotPassword">
              <MyButton name="Send" target="" url=""></MyButton>
            </div>
          </div>
        </form>
        <div v-if="done" class="gotit-wrapper">
          <a href="/login" class="link-wrapper"> Go to login screen </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { strapi_api } from "@/scripts/strapi_api";
export default {
  name: "ForgotPassword",

  data() {
    return {
      strapi_api,
      email: "",
      done: false,
      error: false,
      errorMsg: "",
    };
  },
  methods: {
    async forgotPassword(e) {
      e.preventDefault();
      strapi_api
        .post_strapi(this.axios, "auth/forgot-password", {
          email: this.email,
        })
        .then(
          (response) => {
            //Promisse returned successfully
            if (response.status != 200) {
              this.error = true;
              this.errorMsg = response.data.error.message;
              return;
            } else {
              this.done = true;
            }
          },
          () => {
            //something went wrong with promissew
          }
        );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/views/login";
</style>
