import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/router";
import axios from 'axios';
import VueAxios from 'vue-axios';
import LazyLoading from 'vue-lazy-loading'
import AOS from 'aos'
import 'aos/dist/aos.css'

import Button from '@/components/MyButton.vue';
import LinkArrow from '@/components/LinkArrow.vue';
import SidebarSection from '@/components/SidebarSection.vue';
import SocialIcons from '@/components/SocialIcons.vue';
import InnerFooter from '@/components/InnerFooter.vue';
import i18n from './i18n'


router.beforeEach((to, from, next) => {
    document.body.classList.remove('route-' + from.name)
    document.body.classList.add('route-' + to.name)
    next()
})

const app = createApp(App).use(i18n).use(router).use(AOS).use(LazyLoading, {
    useNative: false
});
app.use(VueAxios, axios);

app.component('MyButton', Button);
app.component('LinkArrow', LinkArrow);
app.component('SidebarSection', SidebarSection);
app.component('SocialIcons', SocialIcons);
app.component('InnerFooter', InnerFooter);

app.mount("#app");