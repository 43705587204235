<template>
    <div class="social-icons">
        <a :href="icon.url" :target="icon.target" v-for="icon in icons">
            <svg :class="'ico-' + icon.name">
                <use :href="base_url + icon.name"/>
            </svg>
        </a>
    </div>
</template>
  
<script>
  export default {
    name: "SocialIcons",
    props: {
      icons: Array
    },
    data(){
        return{
            base_url : require("@/assets/icons.svg") + '#ico-'
        }
    }
  };
  </script>
<style lang="scss" scoped>
    @import '@/style/components/social-icons';
</style>
