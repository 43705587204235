<template>
  <div class="inner-footer extend-both">
    <div class="container">
      <div class="top">
        <div class="links-wrapper">
          <a href="https://www.tinsight.pt" target="_blank"> tinsight.pt </a>
          <a href="https://www.instagram.com/t_insight/" target="_blank"> Instagram </a>
          <a href="https://www.facebook.com/tinsight/" target="_blank"> Facebook </a>
          <a href="https://www.linkedin.com/company/t_insight/mycompany/" target="_blank">
            LinkedIn
          </a>
        </div>
        <div class="talk-to-us">
          <div class="title">Need help? Talk to us:</div>
          <a href="mailto:geral@tinsight.pt"> geral@tinsight.pt </a>
        </div>
      </div>
      <div class="bottom">
        <div class="left">
          <div class="copyright">© t_insight 2024</div>
          <div class="legal-links">
            <!--  <a href="#">
                            Terms of use
                        </a>
                        <a href="#">
                            Privacy
                        </a>
                        <a href="#">
                            Cookies
                        </a> -->
          </div>
        </div>
        <div class="version">Guidelines version 2.0</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InnerFooter",
};
</script>

<style lang="scss" scoped>
@import "@/style/components/inner-footer";
</style>
